<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="true"
          :showAddNew="false"
          title="Company Info"
          @onClickAddNewButton="onClickAddNewButton"
          @onClickSettingButton="onClickSettingButton"
      />
    </div>

    <div class="mb-2"></div>
    <div class="px-2">
      <div class="row mb-3">
        <div class="col-12">
          <label for="colFormLabel" class="col-form-label">Company Name</label>
          <input class="form-control" type="text" name="company_name" v-model="company_name">
        </div>

        <div class="col-12">
          <label for="colFormLabel" class="col-form-label">Company Address</label>
          <textarea class="form-control" name="company_address" v-model="company_address" rows="3"></textarea>
        </div>

        <div class="col-6">
          <label for="colFormLabel" class="col-form-label">Company Email</label>
          <input class="form-control" type="email" name="company_email" v-model="company_email">
        </div>

        <div class="col-6">
          <label for="colFormLabel" class="col-form-label">Company Contact No</label>
          <input class="form-control" type="text" name="company_contact_no" v-model="company_contact_no">
        </div>

        <div class="col-12">
          <label for="colFormLabel" class="col-form-label">Industry</label>
          <input class="form-control" type="text" name="industry" v-model="industry">
        </div>

        <div class="col-6">
          <label for="formFile" class="col-form-label">Upload Logo</label>
          <input @change="onUploadLogo" class="form-control" type="file" id="uploadLogo">
        </div>

        <div class="col-6">
          <label for="formFile" class="col-form-label">Upload Cover Photo</label>
          <input @change="onUploadCoverPhoto" class="form-control" type="file" id="uploadCoverPhoto">
        </div>

        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
              style="min-width: 64px;margin-top: 1rem;"
              @click="onClickSearchButton"
              class="btn btn-primary waves-effect waves-float waves-light form-control"
          >Save
          </button>
        </div>
        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
              style="min-width: 64px;margin-top: 1rem;"
              class="btn btn-secondary waves-effect waves-float waves-light form-control"
          >Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import TitleButton     from '@/components/atom/TitleButton'
import Pagination      from '@/components/atom/Pagination'
import Loader          from '@/components/atom/LoaderComponent'
import {inject}        from "vue";

function onClickAddNewButton() {
  alert('Add New Button clicked');
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

function onClickSearchButton() {
  alert('Search Button clicked');
}

function onUploadLogo() {
  //
}

function onUploadCoverPhoto() {
  //
}

function getJournals() {
  //todo
}

function onPageChange() {
  //todo
}
</script>
